import React from "react"

import { navigate } from "gatsby"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { AuthContext } from "../store"

const ChangeEmailPage = () => {
  const { isAuthenticated, changeEmail } = React.useContext(AuthContext)

  const [newEmail, setNewEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (e) => {
    e.preventDefault()
    changeEmail(newEmail, password)
  }

  return (
    <AuthWrapper title="Change Email">
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="email"
          id="email"
          label="email"
          placeholder="New Email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          isBlock
          required
        />
        <Input
          type="password"
          id="password"
          label="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Update
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default ChangeEmailPage
